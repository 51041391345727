import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { SiVk, SiInstagram } from "react-icons/si";
import "./SideMenu.css";

function SideMenu({ isOpen, closeMenu }) {
  const [categories, setCategories] = useState([]);
  const [subCategoriesCache, setSubCategoriesCache] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [loadingSubCategories] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`
        );
        if (!response.ok) {
          throw new Error("Не удалось загрузить категории");
        }
        const data = await response.json();
        setCategories(data);
        await Promise.all(
          data.map(async (category) => {
            if (category.dropdown) {
              const subResponse = await fetch(
                `${process.env.REACT_APP_API_URL}/categories/${category.id}/types`
              );
              if (subResponse.ok) {
                const subData = await subResponse.json();
                setSubCategoriesCache((prev) => ({
                  ...prev,
                  [category.id]: subData,
                }));
              } else {
                console.error(
                  `Не удалось загрузить типы товаров для категории ${category.id}`
                );
              }
            }
          })
        );
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setActiveCategory(null);
    }
  }, [isOpen]);

  const toggleCategory = (categoryId) => {
    if (activeCategory === categoryId) {
      setActiveCategory(null);
    } else {
      setActiveCategory(categoryId);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="side-menu"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.3, type: "easeOut" }}
        >
          <div className="side-menu-content">
            <button className="close-menu" onClick={closeMenu}>
              ×
            </button>
            <nav>
              <ul className="menu-list">
                <li>
                  <span className="menu-header">Галерея</span>
                  <ul className="categories-list">
                    {categories.map((category) => (
                      <li key={category.id} className="category-item">
                        {category.dropdown ? (
                          <>
                            <button
                              onClick={() => toggleCategory(category.id)}
                              className={`dropdown-button ${
                                activeCategory === category.id ? "active" : ""
                              }`}
                            >
                              {category.title}
                              <span
                                className={`arrow ${
                                  activeCategory === category.id ? "rotate" : ""
                                }`}
                              >
                                ▼
                              </span>
                            </button>
                            <AnimatePresence>
                              {activeCategory === category.id && (
                                <motion.ul
                                  className="submenu"
                                  initial={{ height: 0, opacity: 0 }}
                                  animate={{ height: "auto", opacity: 1 }}
                                  exit={{ height: 0, opacity: 0 }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 300,
                                    damping: 30,
                                  }}
                                >
                                  {error && <li className="error">{error}</li>}
                                  {!loadingSubCategories &&
                                    subCategoriesCache[category.id]?.map(
                                      (subCategory) => (
                                        <li key={subCategory.id}>
                                          <Link
                                            to={`/products/${category.id}/${subCategory.id}`}
                                            onClick={closeMenu}
                                          >
                                            {subCategory.title}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                </motion.ul>
                              )}
                            </AnimatePresence>
                          </>
                        ) : (
                          <Link
                            to={`/products/${category.id}`}
                            onClick={closeMenu}
                          >
                            {category.title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>

                <li>
                  <Link to="/about" onClick={closeMenu}>
                    О нас
                  </Link>
                </li>
                <li>
                  <a href="https://t.me/biksbitstore">Контакты</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer-left-menu">
            <img src="/logo.svg" alt="Logo" className="footer-logo-menu" />
            <div className="social-icons-menu">
              <a
                href="https://vk.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiVk size={26} color="black" />
              </a>
              <a
                href="https://www.instagram.com/biksbit.store?igsh=cDByNjY1d3Z6eXky"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiInstagram size={26} color="black" />
              </a>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default SideMenu;
