import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "./ProductDetails.css";
import { useCart } from "../context/CartContext";
import { useFavorites } from "../context/FavoritesContext";
import { TiHeartOutline, TiHeartFullOutline } from "react-icons/ti";
import { motion } from "framer-motion";
import { SiVk, SiInstagram } from "react-icons/si";
import Faq from "react-faq-component";

function ProductDetails() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { updateCartCount } = useCart();
  const { favorites, toggleFavorite } = useFavorites();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  useEffect(() => {
    if (productId) {
      fetch(`${process.env.REACT_APP_API_URL}/products/${productId}`)
        .then((response) => response.json())
        .then((data) => {
          setProduct(data);
          setRecommendedProducts(data.pinned_products || []);
        })
        .catch((error) => {
          console.error("Ошибка загрузки данных:", error);
        });
    }
  }, [productId]);

  useEffect(() => {
    document.body.style.overflow = isFullScreen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isFullScreen]);

  const handleAddToCart = async () => {
    try {
      setIsAddingToCart(true);
      const jwtToken = localStorage.getItem("jwtToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cart/${productId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (response.ok) {
        updateCartCount();
      } else {
        console.error("Ошибка добавления товара в корзину:", await response.json());
      }
    } catch (error) {
      console.error("Ошибка запроса:", error);
    } finally {
      setIsAddingToCart(false);
    }
  };

  if (!product || !product.photos) {
    return <div>Загрузка...</div>;
  }

  const data = {
    rows: [
      {
        title: "РЕКОМЕНДАЦИИ ПО УХОДУ",
        content:
          '<ul style="padding-left: 20px; margin: 10px 0;">' +
          '<li>По возможности оформляйте работу в раму сразу, или положите вышитую работу в индивидуальный пакет, чтобы предотвратить попадание пыли</li>' +
          '<li>Стирать картины из бисера и хрусталя не желательно, однако если такая необходимость возникла, следует делать это очень осторожно:' +
          '<ul style="padding-left: 20px; margin: 10px 0;">' +
          '<li>стирать только вручную</li>' +
          '<li>не применять моющие средства</li>' +
          '<li>ни в коем случае не выкручивать</li>' +
          '<li>разложить на прямой поверхности до полного высыхания</li>' +
          "</ul>" +
          "</li>" +
          "</ul>",
      },
      {
        title: "ДОСТАВКА",
        content: `Доставка — важный этап в вашем знакомстве с нашими уникальными изделиями. Каждая работа создаётся вручную, что требует времени и внимательности. Мы прилагаем все усилия, чтобы изделие было выполнено с максимальным качеством и любовью, и в связи с этим процесс его изготовления занимает определённое время.\n\nУчтите, что наши работы имеют хрупкую природу, и мы уделяем особое внимание упаковке и транспортировке, чтобы обеспечить их сохранность и заказ прибыл к вам в идеальном состоянии.\n\nМы благодарим вас за терпение и понимание!`,
      },
    ],
  };

  const styles = {
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContent: {
      whiteSpace: "pre-line",
    },
  };

  const config = {
    animate: true,
    expandIcon: "+",
    collapseIcon: "-",
  };

  return (
    <div className="product-details">
      <div className="gallery">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          lazy={{ loadPrevNext: true }}
          loop={true}
          modules={[Navigation, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          className="main-gallery"
        >
          {product.photos.map((src, index) => (
            <SwiperSlide key={index}>
              <img
                src={process.env.REACT_APP_API_URL + src}
                alt={`photo${index + 1}`}
                className="gallery-image"
                onClick={() => setIsFullScreen(true)}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          spaceBetween={5}
          slidesPerView={8}
          onSwiper={setThumbsSwiper}
          watchSlidesProgress
          modules={[Thumbs]}
          className="thumb-gallery"
        >
          {product.photos.map((src, index) => (
            <SwiperSlide key={index}>
              <img
                src={process.env.REACT_APP_API_URL + src}
                alt={`Thumbnail ${index + 1}`}
                className="thumb-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {isFullScreen && (
        <div className="fullscreen-gallery">
          <button
            className="close-gallery-button"
            onClick={() => setIsFullScreen(false)}
          >
            ✕
          </button>

          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            zoom={true}
            modules={[Navigation, Zoom]}
            className="fullscreen-swiper"
          >
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            {product.photos.map((src, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container">
                  <img
                    src={process.env.REACT_APP_API_URL + src}
                    alt={`photo${index + 1}`}
                    className="fullscreen-image"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <div className="product-info">
        <h1 className="product-details-title">{product.title}</h1>
        <div className="product-prices">
          <span className="price">{product.price} ₽</span>
          {product.old_price > 0 && (
            <span className="old-price">{product.old_price} ₽</span>
          )}
        </div>
        <div className="product-actions">
          <button
            className="add-to-cart"
            onClick={handleAddToCart}
            disabled={isAddingToCart}
          >
            Добавить в корзину
          </button>
          <motion.div
            className={`favorite-icon-details ${
              favorites.has(product.id) ? "favorited" : ""
            }`}
            onClick={() => toggleFavorite(product.id)}
            whileTap={{ scale: 0.8 }}
            transition={{ type: "spring", stiffness: 400, damping: 40 }}
          >
            {favorites.has(product.id) ? (
              <TiHeartFullOutline size={24} color="#008ab8" />
            ) : (
              <TiHeartOutline size={24} color="#000" />
            )}
          </motion.div>
        </div>
        <div
          className="product-description"
          dangerouslySetInnerHTML={{ __html: product.description }}
        ></div>
      </div>
      <Faq data={data} styles={styles} config={config} />

      {recommendedProducts && recommendedProducts.length > 0 && (
        <div className="recommended-products">
          <h2 className="recommended-title">С этим товаром также покупают:</h2>
          <div className="recommended-grid">
            {recommendedProducts.map((item) => (
              <Link
                to={`/product/${item.id}`}
                key={item.id}
                className="recommended-item"
              >
                <div className="recommended-image-container">
                  <img
                    src={process.env.REACT_APP_API_URL + item.coverSrc}
                    alt={item.title}
                    className="recommended-image"
                  />
                  <motion.div
                    className={`favorite-icon-recommended ${
                      favorites.has(item.id) ? "favorited" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleFavorite(item.id);
                    }}
                    whileTap={{ scale: 0.8 }}
                    transition={{ type: "spring", stiffness: 400, damping: 40 }}
                  >
                    {favorites.has(item.id) ? (
                      <TiHeartFullOutline size={24} color="#008ab8" />
                    ) : (
                      <TiHeartOutline size={24} color="#000" />
                    )}
                  </motion.div>
                </div>
                <h3 className="recommended-product-title">{item.title}</h3>
                <div className="recommended-price">
                  <span className="price">{item.price} ₽</span>
                  {item.old_price > 0 && (
                    <span className="old-price">{item.old_price} ₽</span>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}

      <footer className="footer">
        <div className="footer-left">
          <img src="/logo.svg" alt="Logo" className="footer-logo" />
          <div className="social-icons-product">
            <a href="https://vk.com" target="_blank" rel="noopener noreferrer">
              <SiVk size={22} color="#000" />
            </a>
            <a
              href="https://www.instagram.com/biksbit.store?igsh=cDByNjY1d3Z6eXky"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiInstagram size={22} color="#000" />
            </a>
          </div>
        </div>
        <div className="footer-right">
          <ul>
            <li>
              <a href="https://t.me/biksbitstore">Контакты</a>
            </li>
            <li>
              <a href="/terms">Пользовательское соглашение</a>
            </li>
            <li>
              <a href="/privacy">Политика конфиденциальности</a>
            </li>
            <li>
              <p>{new Date().getFullYear()} All rights reserved</p>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default ProductDetails;
