import React from 'react'
import './Policy.css'

function Policy() {
  return (
    <div>
      <h1 className="policy-title">Политика конфиденциальности</h1>
      <p className="policy-text">
        Сообщая <strong>biksbit.store</strong> свой <strong>e-mail</strong> и
        номер телефона, Посетитель Сайта/Пользователь/Покупатель дает согласие
        на использование указанных средств связи <strong>Biksbit</strong>, а
        также третьими лицами, привлекаемыми им для целей выполнения
        обязательств им для целей выполнения обязательств перед Посетителями
        им для целей выполнения обязательств перед Посетителями Сайта/Пользователями/Покупателями, в целях осуществления рассылок рекламного и информационного характера, содержащих информацию о скидках, предстоящих и действующих акциях и других мероприятиях Продавца, о передаче заказа в доставку, а также иную информацию, непосредственно связанную с выполнением обязательств Покупателем в рамках настоящей Публичной оферты.
        Сайта/Пользователями/Покупателями, в целях осуществления рассылок
        рекламного и информационного характера, содержащих информацию о скидках,
        предстоящих и действующих акциях и других мероприятиях Продавца, о
        передаче заказа в доставку, а также иную информацию, непосредственно
        связанную с выполнением обязательств Покупателем в рамках настоящей
        Публичной оферты.
      </p>
    </div>
  );
}

export default Policy