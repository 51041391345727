import React from "react";
import "./Terms.css";

function Terms() {
  return (
    <div>
      <h1 className="terms-title">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
      <p className="terms-text">
        ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ Подписываясь на сайте biksbit.store Вы
        обязуетесь: Заполняя форму заказа, при подсписке на SMS рассылку,
        указывать номер мобильного телефона, выделенного оператором сотовой
        связи на Ваше имя. Принимать надлежащие меры для обеспечения сохранности
        Вашего номера мобильного телефона при заполнении формы заказа,
        запрашиваемой Сайтом; Возместить любой ущерб, который может быть
        причинен в связи с указанием некорректных данных, а также за любые
        действия, совершенные Вами ИЛИ ТРЕТЬИМИ ЛИЦАМИ, КОТОРЫМ Вы предоставили
        данные с Вашим номером мобильного телефона, в том числе при
        использовании чужого номера телефона для оформления заказа, а также за
        любые последствия, которые могли повлечь или повлекло подобное их
        использование. Подписка на получение SMS рассылки доступна только для
        Вашего личного некоммерческого использования. Сама подписка на получение
        SMS рассылки, а так же получение самих сообщений SMS рассылки, являются
        для Вас бесплатным. При подписке на SMS рассылку запрещается:
        Использовать номера мобильных телефонов любых третьих лиц без их личного
        согласия на такие действия; Администратор Сайта настоятельно закрепляет
        за собой право удалить Ваши данные из подписки НА SMS рассылку и/или
        приостановить, ограничить или прекратить отправку на Ваш номер SMS
        сообщений, на которые Вы подписывались, если администратор Сайта
        обнаружит, что по его мнению Вы представляете угрозу для Сайта и/или его
        пользователям. Вы несете личную ответственность за всю информацию,
        которую Вы размещаете в форме оформления заказа на Сайте и подписке на
        SMS рассылку. Размещаю свою информацию в регистрационной форме на Сайте
        при подписке на SMS рассылку, Вы уполномочиваете Администратора Сайта
        делать архивные резервные копии базы данных сайта, которые могут
        включать в себя Вашу регистрационную информацию, с целью упорядочения и
        хранения информации, представленной на Сайте. Если Вы решите удалить
        свои регистрационные данные, «отписавшись» от SMS рассылки, Вам стоит
        иметь в виду, что Администратор Сайта в обязательном порядке сохраняет
        архивные резервные копии Ваших регистрационных данных. Отписаться от
        получения СМС-сообщений можно следующим способам: — написав
        соответствующее письмо на адрес электронной почты клуба
        support@biksbit.store Biksbit.store не несут ответственности за любые
        ошибки, упущения, прерывания, удаление, дефекты, задержку в обработке
        или передача сообщений с SMS рассылкой, сбое линий связи. Администратор
        Сайта не отвечает за любые технические сбои или иные проблемы любых
        телефонных сетей, служб или операторов связи, компьютерных систем,
        серверов или провайдеров, компьютерного или телефонного оборудования,
        программного обеспечения, сбоев сервисов электронной почты или скриптов
        по техническим причинам. Администратор Сайта сохраняет за собой право в
        любое время изменять оформление сайта, его контент, список сервисов,
        изменять или дополнять используемые скрипты, программное обеспечение и
        другие объекты, используемые или хранящиеся на сайте, любые серверные
        приложения в любое время с предварительным уведомлением или без
        такового. Ни при каких обстоятельствах Администратор Сайта или его
        представители не несут ответственности перед Вами или перед любыми
        третьими лицами за любой косвенный, случайный, неумышленный ущерб,
        включая упущенную выгоду или потерянные данные, вред чести, достоинству
        или деловой репутации, вызванной в связи с использованием сайта,
        содержимого сайта или иных материалов, к которым вы или иные лица
        получили доступ с помощью сайта, даже если администрация предупреждала
        или указывала на возможность такого вреда. Администратор Сайта
        подтверждает сохранение в тайне и не предоставление третьим лицам, за
        исключением оператора связи, непосредственно осуществляющего передачу
        сообщений SMS рассылки, Ваших регистрационных данных, оставленных Вами
        при подписке на получение SMS рассылки. Компания гарантирует, что не
        будет использовать номер мобильного телефона, указанный Вами при
        подписке на получение сообщений SMS рассылки, для рассылки какой-либо
        информации, кроме той, на которую Вы подписались. Принимая настоящее
        пользовательское соглашение, я подтверждаю, что даю свое согласие на
        получение на указанный мной при регистрации номер мобильного телефона
        адрес рекламной информации от Компании в виде сообщений SMS рассылки с
        информацией о выходе новых акций, скидок или персональных предложений.
        Условия настоящего пользовательского соглашения принимаю. Обновлено: 1
        февраля 2022 года Terms & Conditions Оплата: Для оплаты (ввода
        реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз
        Сбербанка. Соединение с платежным шлюзом и передача информации
        осуществляется в защищенном режиме с использованием протокола шифрования
        SSL. Обмен & Возврат: support@biksbit.store Примечание: Макеты товара на
        сайте могут незначительно отличаться от фактического товара. Допустимые
        отличия: – Цвет ( экран каждого электронного устройства имеет различную
        цветопередачу, допустимое различие на 2-3 тона ) – Люверсы – Шнурки
        Правила возврата товаров. Возврат товара надлежащего качества: Возврат
        товара осуществляется посредством оформления заявки в свободной форме с
        указанием номера заказа, ФИО покупателя, номера заказа, заверенной
        личной подписью покупателя, и направленным сканом обращения на
        электронную почту: support@biksbit.store Срок возврата товара
        надлежащего качества составляет 7 дней с момента получения товара.
        Причиной для возврата товара со стороны Покупателя является неподошедший
        по размеру. Товар принимается к возврату только в полной комплектации,
        со всеми упаковками и наклейками, в неношеном/неиспользованном виде
        Возврат товара надлежащего качества осуществляется двумя способами:
        Возврат в пункт выдачи заказов. Отправка курьерской доставкой. Возврат
        товара ненадлежащего качества. Срок возврата товара: Причиной для
        возврата товара со стороны Покупателя может быть производственный
        недостаток (брак) товара. Покупатель может обратиться с требованием о
        возврате такого товара в течение 14 дней с момента получения товара.
        Возврат товара ненадлежащего качества осуществляется следующим способом:
        Возврат товара осуществляется посредством оформления заявки в свободной
        форме с указанием номера заказа, ФИО покупателя, номера заказа,
        заверенной личной подписью покупателя, и направленным сканом обращения
        на электронную почту: support@biksbit.store Срок рассмотрения заявки не
        превышает 7 (семь) рабочих дней. В случае, если по результату
        рассмотрения заявки Продавцом принято положительное решение, покупатель
        может передать товар для возврата в пункт выдачи заказов или отправить В
        случае отклонения заявки, покупатель вправе обратиться в независимую
        экспертизу. В случае, если независимой экспертизой будет подтверждено
        право покупателя на возврат товара, все расходы покупателя, связанные с
        проведением независимой экспертизы подлежат возмещению Продавцом.
        Возврат товара предзаказа: Предзаказ – индивидуальный пошив товара,
        имеющий индивидуально-определенные свойства. Возврат товара с предзаказа
        надлежащего качества Отказ от товара и возврат в размере 100 %
        предоплаты – не возможен, в связи с индвидуально-определёнными
        свойствами. Возврат товара осуществляется с компенсацией покупателю в
        размере 30% от стоимости товара на момент оформления предзаказа. Возврат
        товара осуществляется посредством оформления заявки в свободной форме с
        указанием номера заказа, ФИО покупателя, номера заказа, заверенной
        личной подписью покупателя, и направленным сканом обращения на
        электронную почту: support@biksbit.store Возврат товара с предзаказа
        ненадлежащего качества. Причиной для возврата товара со стороны
        Покупателя может быть производственный недостаток (брак) товара.
        Покупатель может обратиться с требованием о возврате такого товара в
        течение 14 дней с момента получения товара. Возврат товара с предзаказа
        ненадлежащего качества осуществляется следующим способом: Возврат товара
        осуществляется посредством оформления заявки в свободной форме с
        указанием номера заказа, ФИО покупателя, номера заказа, заверенной
        личной подписью покупателя, и направленным сканом обращения на
        электронную почту: biksbit.store Срок рассмотрения заявки не превышает 7
        (семь) рабочих дней. В случае, если по результату рассмотрения заявки
        Продавцом принято положительное решение, покупатель может передать товар
        для возврата в пункт выдачи заказов или отправить В случае отклонения
        заявки, покупатель вправе обратиться в независимую экспертизу. В случае,
        если независимой экспертизой будет подтверждено право покупателя на
        возврат товара, все расходы покупателя, связанные с проведением
        независимой экспертизы подлежат возмещению Продавцом.
      </p>
    </div>
  );
}

export default Terms;
