import React from "react";
import "./About.css";

function About() {
  return (
    <div>
      <p className="about-text">
        Добро пожаловать в мир уникального творчества! <strong>Biksbit</strong> —
        команда преданных своему делу мастеров, увлечённых созданием изысканных
        изделий ручной работы. Каждое наше творение — это результат кропотливого
        труда, любви к искусству и внимания к деталям. Наши мастера работают в
        уникальном стиле, который придаёт душу и характер каждому изделию. Мы
        используем только премиальные материалы, чтобы гарантировать, что наши
        работы не только красивы, но и долговечны. Мы верим, что искусство
        должно не только наслаждать, но и вдохновлять. Наши изделия наполнят ваш
        дом теплом и создадут атмосферу уюта. Присоединяйтесь к нашей семье
        ценителей ручной работы, и пусть каждая покупка станет для вас маленьким
        произведением искусства!
      </p>
    </div>
  );
}

export default About;
